import * as React from 'react';
import { createWidget } from '@typeform/embed';
import '@typeform/embed/build/css/widget.css';

import SEO from '../components/seo';
import Layout from '../components/layout';

export interface IDevisPageProps {}

const DevisPage: React.FC<IDevisPageProps> = (props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    ref.current && createWidget('sfT4iLV5', { container: ref.current });
  });
  return (
    <Layout>
      <SEO title="Devis" />
      <div ref={ref} style={{ flex: 1 }}></div>
    </Layout>
  );
};

export default DevisPage;
